import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import Card from '@material-ui/core/Card';
import { CardMedia, CardActionArea, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import img1 from '../../assets/industries/banking.jpg';
import img2 from '../../assets/industries/healthcare.jpg';
import img3 from '../../assets/industries/insurance.jpg';
import img4 from '../../assets/industries/retail.jpg';
import img5 from '../../assets/industries/tele.jpg';
import img6 from '../../assets/industries/travel.jpg';
import img7 from '../../assets/industries/utilities.jpg';

export default class Industries extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu0" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Industries Overview</h1>
                        </div>
                    </section>
                </FadeInSection>
                <br />
                <FadeInSection>
                    <div align="center">
                        <h2 className="htxt">Explore Our Industries</h2>
                    </div>
                </FadeInSection>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img1} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Banking
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                The Banking and Capital Markets industry is witnessing unprecedented competition. 
                                                Not only from traditional providers but also entrants from retail....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/industries/banking">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img2} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Healthcare
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Thought Circuits collaborates with the world's leading Life Sciences 
                                                organizations to enable change through strategy, insight, design....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/industries/healthcare">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img3} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Insurance
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Digital transformation is here. 
                                                Discover how we help life insurers redefine their business and 
                                                operating models and position themselves for growth....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/industries/insurance">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img4} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Retail
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Consumer preferences for what they want to buy and where and how
                                                they want to buy it change fast. The CPG and retail industries....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/industries/retail">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img5} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Telecommunications
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                In rapidly changing markets, Technology, Media & Entertainment, 
                                                and Telecommunications companies must redefine the....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/industries/telecomm">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img6} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Travel
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Today's travel, transportation and hospitality businesses 
                                                face increasing competitive pressure to provide the best customer....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/industries/travel">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img7} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Energy and Utilities
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Energy and utility companies feel a level of disruption not seen in decades. 
                                                New, cash-rich competitors are disrupting the markets. Renewable prices....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/industries/utilities">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}