import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import AboutUs from './components/About/AboutUs';
import Careers from './components/Careers/Careers';
import Clients from './components/Clients/Clients';
import Contact from './components/Contact/Contact';
import Industries from './components/Industries/Industries';
import Services from './components/Services/Services';
import Home from './components/Home/Home';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import Banking from './components/Industries/Banking';
import Healthcare from './components/Industries/Healthcare';
import Insurance from './components/Industries/Insurance';
import Retail from './components/Industries/Retail';
import TeleCommunication from './components/Industries/TeleCommunication';
import Travel from './components/Industries/Travel';
import Utilities from './components/Industries/Utilities';
import AppServices from './components/Services/AppServices';
import BPService from './components/Services/BPService';
import CloudService from './components/Services/CloudService';
import SecurityService from './components/Services/SecurityService';
import ConsultingService from './components/Services/ConsultingService';
import FadeInSection from './components/FadeInSection';

function App() {
    return (
        <div>
            <Header />
            <div>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/aboutus" component={AboutUs} />
                        <Route exact path="/services" component={Services} />
                        <Route exact path="/services/appservice" component={AppServices} />
                        <Route exact path="/services/bpservice" component={BPService} />
                        <Route exact path="/services/cloudservice" component={CloudService} />
                        <Route exact path="/services/security" component={SecurityService} />
                        <Route exact path="/services/consulting" component={ConsultingService} />

                        <Route exact path="/industries" component={Industries} />
                        <Route exact path="/industries/banking" component={Banking} />
                        <Route exact path="/industries/healthcare" component={Healthcare} />
                        <Route exact path="/industries/insurance" component={Insurance} />
                        <Route exact path="/industries/retail" component={Retail} />
                        <Route exact path="/industries/telecomm" component={TeleCommunication} />
                        <Route exact path="/industries/travel" component={Travel} />
                        <Route exact path="/industries/utilities" component={Utilities} />

                        <Route exact path="/clients" component={Clients} />
                        <Route exact path="/careers" component={Careers} />
                        <Route exact path="/contact" component={Contact} />
                    </Switch>
                </Router>
            </div>
            <FadeInSection>
                <Footer />
            </FadeInSection>
        </div>

    );
}

export default App;
