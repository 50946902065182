import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';

export default class Travel extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu6" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Travel, Transportation and Hospitality</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div>
                    <FadeInSection>
                        <br />
                        <div className="container">
                            <h5 className="htxt">
                                Today's travel, transportation and hospitality businesses face increasing competitive pressure to 
                                provide the best customer experience, build loyalty and expand services. Whether helping people or
                                goods reach their destinations, industry leaders are leveraging new technologies to modernize aging 
                                infrastructure and outdated processes, as well as embracing connected platforms to help them deliver
                                differentiated services and experiences that delight travelers.
                            </h5>
                            <br />
                            <p>
                                The travel, logistics, hospitality, and transportation services industry is witnessing large 
                                scale shifts and changes.
                            </p>
                            <p>
                                Logistics competencies grew by leaps and bounds in the 1990s. However, the landscape in the digital 
                                age is vastly different. The internet has unleashed an immense potential to communicate and collaborate,
                                giving rise to advanced travel management software and tools. Meanwhile, there is a tremendous surge 
                                in supply chain velocity, while the transport software segment evolves in tandem. This leads to
                                reduced costs through information sharing and synchronization between trading partners and 
                                transportation service providers.
                            </p>
                            <p>
                                As a result, companies are re-envisioning existing frameworks, processes, and systems around 
                                transport and logistics management leading to a holistic overhaul of operations and strategy. 
                                The objective is to harness the power of digital in order to drive down costs, enhance supply 
                                chain integration, and increase market power through customer fulfilment.
                            </p>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}