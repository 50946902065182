import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import './Services.css';
import { Checkmark } from 'react-checkmark';

export default class AppServices extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgserv1" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Application Services</h1>
                        </div>
                    </section>
                </FadeInSection>
                <br />
                <FadeInSection>
                    <div className="container">
                        <h4 className="htxt">Business-focused Application Services that accelerate growth and support
                            your non-stop drive to business excellence.</h4>
                        <br />
                        <p>
                            In todays chaotic digital marketplace, consumers are more demanding than ever
                            and customer application loyalty has almost become synonymous with customer brand loyalty.
                        </p>
                        <p>
                            This means that forward-thinking businesses are looking to their Application Services and IT functions to create the experiences their customers want, while consistently bringing more business value by:
                        </p>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Driving more disruption, innovation, and change</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Reducing complexity and providing easy-to-consume services</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Developing a collaborative eco-system to broker services for the business</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Moving away from operational activities to better support the organizations strategy</span>
                                </li>
                            </ul>
                        </div>
                        <p>
                            Our experts will collaborate with your teams to develop a business-value-focused Application
                            Services and IT strategy to fit your unique requirements.
                            We believe that this human-centered approach to technology is what makes the
                            difference for your business.
                        </p>
                    </div>
                </FadeInSection>
                <br />
                <br />
                <br />
            </div>
        );
    }
}