import React, { Component } from 'react';
import { Fade } from "react-slideshow-image";

import './Home.css';
import homemainimg from '../../assets/homeimg.png';
import homevdsrc from '../../assets/home.mp4';

import hiringimg from '../../assets/hiring_home.jpg';

import 'react-slideshow-image/dist/styles.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";

import codingicon from '../../assets/home/coding.svg';
import connicon from '../../assets/home/connection.svg';
import sdicon from '../../assets/home/cons.svg';

import FadeInSection from '../FadeInSection';

export default class Home extends Component {

    render() {
        const indusimgs = [require('../../assets/industries/banking.jpg'), require('../../assets/industries/healthcare.jpg'),
        require('../../assets/industries/insurance.jpg'), require('../../assets/industries/retail.jpg'),
        require('../../assets/industries/tele.jpg'), require('../../assets/industries/travel.jpg'),
        require('../../assets/industries/utilities.jpg')];

        const links = { "careers": "/careers" };
        return (
            <div>
                <section className="section1">
                    <div className="home1">
                        <div className="row">
                            <div className="col-6 txthmsize">
                                <div className="container">
                                    <h1 className="htitle"><p>We help <span style={{ color: "orange" }}>Tech companies</span><br /> <span style={{ color: "orange" }}>Scale</span> engineering capacity
                            <br />and <span style={{ color: "orange" }}>Deliver</span> quality software</p></h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <img src={homemainimg} width="100%" alt='' />
                            </div>
                        </div>
                        <video autoplay="autoplay" loop="loop" muted className="homecss" max-width="100%" ><source type="video/mp4" src={homevdsrc} /></video>
                    </div>
                </section>
                <br />
                <section className="servicessec">
                    <FadeInSection>
                        <h1 style={{ color: "orange" }} align="center">Services We Offer</h1>
                        <div className="container">
                            <div className="row">
                                <div className="col-4" align="center">
                                    <img src={codingicon} alt='' style={{ width: '10rem', height: '10rem', padding: '10px' }} />
                                    <br />
                                    <h5>Application Services</h5>
                                </div>
                                <div className="col-4" align="center">
                                    <img src={sdicon} alt='' style={{ width: '10rem', height: '10rem',  padding: '10px' }} />
                                    <br />
                                    <h5>Consulting Services</h5>
                                </div>
                                <div className="col-4" align="center">
                                    <img src={connicon} alt='' style={{ width: '10rem', height: '10rem', padding: '10px' }} />
                                    <br />
                                    <h5>Business Process Services</h5>
                                </div>
                            </div>
                            <br/>
                            <div align="center">
                                <a href="/services" class="btn btn-basic btn-sm svcbtn">LEARN MORE</a>
                            </div>
                        </div>
                    </FadeInSection>
                </section>
                <section className="servicessec">
                    <FadeInSection>
                        <div className="row">
                            <div className="col-6">
                                <div className="slide-container">
                                    <Fade>
                                        <div className="each-fade indusimg">
                                            <img className="indusimg1" width="100%" height="540px" src={indusimgs[0]} />
                                            <div className="leftText">
                                                <h2>Banking and Capital Markets</h2>
                                                <p className="ltp">The Banking and Capital Markets industry is witnessing
                                                    unprecedented competition. Not only from traditional providers
                                                    but also entrants from retail, BigTech and others.
                                                    Customer satisfaction standards must stack up not only to those firms
                                                    providing financial services, but also with these new competitors.</p>
                                            </div>
                                        </div>
                                        <div className="each-fade indusimg">
                                            <img className="indusimg1" width="100%" height="540px" src={indusimgs[1]} />
                                            <div className="leftText">
                                                <h2>Health Care</h2>
                                                <p className="ltp">Thought Circuits collaborates with the world’s leading Life
                                                    Sciences organizations to enable change through strategy, insight,
                                                    design, and technology. We solve our client's business
                                                    challenges through innovative, patient-focused solutions.</p>
                                            </div>
                                        </div>
                                        <div className="each-fade indusimg">
                                            <img className="indusimg1" width="100%" height="540px" src={indusimgs[2]} />
                                            <div className="leftText">
                                                <h2>Insurance</h2>
                                                <p className="ltp">Today’s insurers are marching towards an all-digital superior environment and
                                                    are depending on innovation to retain customers, introduce products faster,
                                                    improve efficiency and reduce costs.</p>
                                            </div>
                                        </div>
                                        <div className="each-fade indusimg">
                                            <img className="indusimg1" width="100%" height="540px" src={indusimgs[3]} />
                                            <div className="leftText">
                                                <h2>Consumer and Retail</h2>
                                                <p className="ltp">What kind of products company are you? That’s right, a consumer products company.
                                                    That’s why smart companies are finding new ways to keep consumers at the center of their business.</p>
                                            </div>
                                        </div>
                                        <div className="each-fade indusimg">
                                            <img className="indusimg1" width="100%" height="540px" src={indusimgs[4]} />
                                            <div className="leftText">
                                                <h2>Technology, Media and Telecommunications</h2>
                                                <p className="ltp">In rapidly changing markets, Technology, Media & Entertainment,
                                                    and Telecommunications companies must redefine the customer experience,
                                                    capitalize on digital convergence and quickly launch new business models.</p>
                                            </div>
                                        </div>
                                        <div className="each-fade indusimg">
                                            <img className="indusimg1" width="100%" height="540px" src={indusimgs[5]} />
                                            <div className="leftText">
                                                <h2>Travel, Transportation and Hospitality</h2>
                                                <p className="ltp">Today’s travel, transportation and hospitality businesses
                                                    face increasing competitive pressure to provide the best customer experience,
                                                    build loyalty and expand services. Whether helping people or goods reach
                                                    their destinations, industry leaders are leveraging new technologies to
                                                    modernize aging infrastructure and outdated processes, as well as embracing
                                                    connected platforms to help them deliver differentiated services and
                                                    experiences that delight travelers.</p>
                                            </div>
                                        </div>
                                        <div className="each-fade indusimg">
                                            <img className="indusimg1" width="100%" height="540px" src={indusimgs[6]} />
                                            <div className="leftText">
                                                <h2>Energy, Utilities, Oil and Gas</h2>
                                                <p className="ltp">Our Customers are using digital technologies to meet the growing
                                                    global demand for energy. Digital technologies offer companies the opportunity
                                                    to create new products quickly, improve worker safety and maintain complex
                                                    infrastructure.</p>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                            <div className="col-6 svcsec1" style={{ backgroundColor: "black" }}>
                                <div class="container" align="center">
                                    <div>
                                        <h5>Business Area</h5>
                                        <h1>What We Do</h1>
                                        <br />
                                        <a href="/industries" className="btn btn-basic btn-sm svc1btn">LEARN MORE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                </section>
                <section className="servicessec">
                    <FadeInSection>
                        <div className="row">
                            <div className="col-6 svcsec2" style={{ backgroundColor: "black" }}>
                                <div class="container" align="center">
                                    <div>
                                        <h5>Careers</h5>
                                        <h1>Join our Team</h1>
                                        <h6>Explore career opportunities at Thought Circuits.</h6>
                                        <a className="btn btn-basic btn-sm svc1btn" href={links.careers}>Check Our Open Positions</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <img width="100%" height="500px" src={hiringimg} />
                            </div>
                        </div>
                    </FadeInSection>
                </section>
            </div>
        );
    }
}