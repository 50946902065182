import React, { Component } from 'react';
import './Clients.css';
import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';
import { Carousel } from "react-responsive-carousel";
import FadeInSection from '../FadeInSection';

export default class Clients extends Component {


    render() {
        const imgs = [require('../../assets/clients/centene.png'), require('../../assets/clients/chrysler.png'),
        require('../../assets/clients/csx.png'), require('../../assets/clients/es.png'),
        require('../../assets/clients/ford.png'), require('../../assets/clients/ibm2.png'),
        require('../../assets/clients/mc.png'), require('../../assets/clients/nagarrao.png'), require('../../assets/clients/nas.png'),
        require('../../assets/clients/rbc1.png'), require('../../assets/clients/techm.png'), require('../../assets/clients/ups.png'),
        require('../../assets/clients/ust.png'), require('../../assets/clients/verizon.png'), require('../../assets/clients/virtusa.png')];
        return (
            <div>
                <section className="clisection2">
                    <img className="imgcss" height="100%" width="100%" />
                    <div className="imgtxt">
                        <h1 className="imgfnt">Clients</h1>
                        <h3>Tech innovators choose Thought Circuits</h3>
                        <div className="infotxt">
                            <p>Thought Circuits helps startups and established technology companies greatly increase their software engineering capacity. Our expertise, organizational knowledge, and enthusiastic approach help you innovate, creating software that outpaces the competition and sets the industry standard.</p>
                        </div>
                    </div>
                </section>
                <br />
                <section className="clisection3">
                    <FadeInSection>
                        <div className="box">
                            <h2 align="center"><strong>We Deliver for</strong></h2>
                            <br />
                            <CardColumns>
                                {
                                    imgs.map((value, index) => {
                                        return <Card style={{ width: '14rem', padding: '10px' }}>
                                            <Card.Img variant="top" src={value} />
                                        </Card>
                                    })
                                }

                            </CardColumns>
                        </div>
                    </FadeInSection>
                </section>
                <br />
                <section className="servicessec">
                    <FadeInSection>
                        <h1 style={{ color: "orange" }} align="center">Client Testimonials</h1>
                        <br />
                        <div className="testidiv">
                            <Carousel autoPlay >
                                <div className="testidiv2">
                                    <div >
                                        <p>Helloooo</p>
                                    </div>
                                    <p className="legend">Legend 1</p>
                                </div>
                                <div>
                                    <p className="legend">Legend 2</p>
                                </div>
                                <div>
                                    <p className="legend">Legend 3</p>
                                </div>
                                <div>
                                    <p className="legend">Legend 4</p>
                                </div>
                            </Carousel>
                        </div>
                    </FadeInSection>
                </section>
            </div>
        );
    }
}