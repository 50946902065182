import React, { Component } from 'react';
import aboutdata from '../../assets/aboutus.json';
import './AboutUs.css';
import FadeInSection from '../FadeInSection';
import { Checkmark } from 'react-checkmark';

export default class AboutUS extends Component {

    select(val) {
        document.getElementById("overview").style.display = "none";
        document.getElementById("culture").style.display = "none";
        document.getElementById("vision").style.display = "none";
        document.getElementById("principals").style.display = "none";
        switch (val) {
            case 1: {
                document.getElementById("overview").style.display = "block";
                break;
            }
            case 2: {
                document.getElementById("culture").style.display = "block";
                break;
            }
            case 3: {
                document.getElementById("vision").style.display = "block";
                break;
            }
            case 4: {
                document.getElementById("principals").style.display = "block";
                break;
            }
        }
    }

    render() {
        const data = aboutdata.data;
        return (
            <div>
                <section className="section1" style={{ backdropFilter: "inherit" }}>
                    <FadeInSection>
                        <img className="imgcss1" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt1">About US</h1>
                        </div>
                    </FadeInSection>
                </section>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <FadeInSection>
                                <div className="animated fadeInUp">
                                    <ul>
                                        <li><h4><strong>Content</strong></h4></li>
                                        <hr/>
                                        <li><a className="aboutmenu" onClick={() => this.select(1)} style={{ fontSize: "16px" }}>Overview</a></li>
                                        <li><a className="aboutmenu" onClick={() => this.select(2)} style={{ fontSize: "16px" }}>Our Culture</a></li>
                                        <li><a className="aboutmenu" onClick={() => this.select(3)} style={{ fontSize: "16px" }}>Our Vision</a></li>
                                        <li><a className="aboutmenu" onClick={() => this.select(4)} style={{ fontSize: "16px" }}>Our Principals</a></li>
                                    </ul>
                                </div>
                            </FadeInSection>
                        </div>
                        <div className="col-9">
                            <br />
                            <div id="overview">
                                <FadeInSection>
                                    <br/>
                                    <div className="pdiv" >
                                        <p>{data}</p>
                                    </div>
                                </FadeInSection>
                            </div>
                            <div id="culture" style={{ display: "none" }}>
                                <FadeInSection>
                                    <br />
                                    <div className="pdiv" >
                                        <p>{aboutdata.culture}</p>
                                    </div>
                                </FadeInSection>
                            </div>
                            <div id="vision" style={{ display: "none" }}>
                                <FadeInSection>
                                    <br />
                                    <div className="pdiv" >
                                        <h5 style={{color:"orange"}}>Our Vision: the business value of technology comes from and through people</h5>
                                        <p>{aboutdata.visiondata}</p>
                                        <h5 style={{ color: "orange" }}>Our Mission: with you, we create and deliver business and technology solutions that fit your needs and drive the results you want</h5>
                                        <p>{aboutdata.missiondata}</p>
                                        <p>People matter, results count.</p>
                                    </div>
                                </FadeInSection>
                            </div>
                            <div id="principals" style={{ display: "none" }}>
                                <FadeInSection>
                                    <br />
                                    <div className="pdiv" >
                                        <p><span style={{ display: "inline-flex" }}><Checkmark size={16} color='#339de8' /> &nbsp;&nbsp; Stay Curious</span></p>
                                        <p><span style={{ display: "inline-flex" }}><Checkmark size={16} color='#339de8' /> &nbsp;&nbsp; Keep Promises</span></p>
                                        <p><span style={{ display: "inline-flex" }}><Checkmark size={16} color='#339de8' /> &nbsp;&nbsp; Customer First</span></p>
                                        <p><span style={{ display: "inline-flex" }}><Checkmark size={16} color='#339de8' /> &nbsp;&nbsp; Passion for Progress</span></p>
                                        <p><span style={{ display: "inline-flex" }}><Checkmark size={16} color='#339de8' /> &nbsp;&nbsp; Insist Highest Standards</span></p>
                                    </div>
                                </FadeInSection>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        );
    }
}