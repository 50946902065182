import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import { Checkmark } from 'react-checkmark';

export default class Insurance extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu3" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Insurance</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div>
                    <FadeInSection>
                        <br />
                        <div className="container">
                            <p>Digital transformation is here. Discover how we help  life insurers redefine their business
                                and operating models and position themselves for growth.</p>
                            <h5 className="htxt">
                                The digital insurer: Creating a more dynamic future
                            </h5>
                            <br />
                            <p>
                                Our insurance consulting services are using AI, cloud and other transformative technologies 
                                to help insurers, life carriers and reinsurers improve their core operations, 
                                develop new sources of revenue growth, and pivot successfully to 'the new'. We are also assisting 
                                them to become more relevant to customers, more agile, and more secure in the face of cyber-threats.
                                In short, we are helping equip them to thrive in a much more dynamic future.  
                            </p>
                            <p>Thought Circuits knows from experience how to help you:</p>
                            <div>
                                <ul>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Property and Casualty Services</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Customer insights and Growth</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Life Insurance Services</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Financial and Risk</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Technology Advisory</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Intelligent Insurance Operations</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}