import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import { Checkmark } from 'react-checkmark';

export default class Banking extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu1" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Banking and Capital Markets</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div>
                    <FadeInSection>
                        <br />
                        <div className="container">
                            <h5 className="htxt">
                                The Banking and Capital Markets industry is witnessing unprecedented competition. 
                                Not only from traditional providers but also entrants from retail, BigTech and others.
                                Customer satisfaction standards must stack up not only to those firms providing financial services, 
                                but also with these new competitors.
                            </h5>
                            <br />
                            <p>
                                To transform, profit and grow, organizations need to create new customer engagement benchmarks, 
                                find new ways to generate revenue and continue to reduce expense. The world's leading banks and
                                capital market firms collaborate with Thought Circuits to reimagine their business models to meet 
                                the evolving demands from the market, customers, partners and the regulators.
                            </p>
                            <p>
                               We put innovation at the center of everything we do and help organizations future proof 
                                 their businesses by developing capabilities across these key five pillars of the Digital 
                                Enterprise of the Future:
                            </p>
                            <div>
                                <ul>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Orchestrating an Open Ecosystem</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Becoming an intelligent Bank</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Driving Customer Centricity</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Enhancing Go To Market Agility</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Driving Customer Centricity</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}