import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import { Checkmark } from 'react-checkmark';

export default class TeleCommunication extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu5" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Technology, Media and Telecommunications</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div>
                    <FadeInSection>
                        <br />
                        <div className="container">
                            <h5 className="htxt">
                                In rapidly changing markets, Technology, Media & Entertainment, and Telecommunications
                                companies must redefine the customer experience, capitalize on digital convergence and
                                quickly launch new business models.
                            </h5>
                            <br />
                            <p>
                                Old and new, these digital pioneers are breaking new ground in technology innovations
                                that open new markets, connect people, democratize information and improve the lives 
                                of global citizens.
                            </p>
                            <p>
                                As companies expand outside traditional industry borders to gain new customers, 
                                there is significant potential if they get it right and much at risk if they get it wrong.
                            </p>
                            <p>
                                Thought Circuits partners with you to reimagine new business models, rethink operational
                                resilience, redesign new experiences and create new value in the digital era.
                            </p>
                            <p>
                                Our Expertise areas:
                            </p>
                            <div>
                                <ul>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Device Driver Development</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Embedded Programming</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Development of DSP Solutions</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Hardware / Software Testing</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; GSM / CDMA Testing</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; VOIP Solutions</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; SIP Solutions</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}