import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import Card from '@material-ui/core/Card';
import { CardMedia, CardActionArea, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import img1 from '../../assets/services/appservice.jpg';
import img2 from '../../assets/services/bpo.jpg';
import img3 from '../../assets/services/cloud.jpg';
import img4 from '../../assets/services/security.jpg';
import img5 from '../../assets/services/consulting.jpg';

export default class Services extends Component {


    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgserv0" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Services Overview</h1>
                        </div>
                    </section>
                </FadeInSection>
                <br />
                <FadeInSection>
                    <div align="center">
                        <h2 className="htxt">Explore Our Services</h2>
                    </div>
                </FadeInSection>
                <br/>
                <div className="container">
                    <div className="row">
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img1} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Application Services
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Business-focused Application Services that accelerate growth and support your
                                                non-stop drive to business excellence....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/services/appservice">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img2} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                BPO Service
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Over the last decade Business Process Outsourcing (BPO) has consistently evolved and
                                                refined its value....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/services/bpservice">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img3} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Cloud Services
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                In the digital era, cloud is the foundation for agility and growth, 
                                                allowing you to harness....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/services/cloudservice">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img4} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Security Services
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Consumer preferences for what they want to buy and where and how
                                                they want to buy it change....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/services/security">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                        <div className="col-4 card1">
                            <FadeInSection>
                                <Card style={{ width: '18rem' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={img5} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Consulting Services
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Thought Circuits staffing division can provide staffing and recruiting assistance 
                                                for resources on an....
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" href="/services/consulting">
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </FadeInSection>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        );
    }
}