import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import './Services.css';
import { Checkmark } from 'react-checkmark';

export default class ConsultingService extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgserv5" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Consulting Services</h1>
                        </div>
                    </section>
                </FadeInSection>
                <br/>
                <div className="container">
                    <FadeInSection>
                        <h4 className="htxt">Consulting Services</h4>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <p>Thought Circuits staffing division can provide staffing and recruiting assistance for resources on an
                            existing project, or can analyze, plan and assign resources to new projects. We possess with a large team of 
                            qualified consultants with various levels of experience
                            in the industry. This allows us to assist you in finding the best-suited candidates for
                            your requirements and projects.</p>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <b><p>We help businesses locate highly skilled IT professionals experienced in a large number of areas:</p></b>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Enterprise Content Management</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Networking</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Desktop Support</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Web Development</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Help Desk/Technical Services</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Software Development</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Data/Database Management </span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Quality Assurance/Test</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Systems Integration</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Infrastructure Management</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Project Management</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Business Analysis</span>
                                </li>
                            </ul>
                        </div>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <p>IT professionals choose Thought Circuits to be their representative due to our rewarding placements
                            and very competitive pay. Our association and partnership with leading industry organizations
                            enables us to provide our employees and consultants with extensive professional development o
                            pportunities, allowing them to consistently enhance their skill sets and increase the value they
                            provide to our clients.</p>
                    </FadeInSection>
                    <br />
                </div>
            </div>
        );
    }
}