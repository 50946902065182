import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import logo from '../../logo.png';
import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FadeInSection from '../FadeInSection';
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default class Header extends Component {

    constructor(props) {
        super(props);
    }

    menuhover() {
        document.getElementById("industriesmenu").style.display = "none";
        document.getElementById("servicesmenu").style.display = "none";
    }

    serviceshover() {
        document.getElementById("industriesmenu").style.display = "none";
        document.getElementById("servicesmenu").style.display = "block";
    }

    serviceshoverout() {
        document.getElementById("servicesmenu").style.display = "none";
    }

    industrieshover() {
        document.getElementById("servicesmenu").style.display = "none";
        document.getElementById("industriesmenu").style.display = "block";
    }

    industrieshoverout() {
        document.getElementById("industriesmenu").style.display = "none";
    }

    render() {
        var pathname = window.location.pathname;
        var style = {
            paddingTop: "0px",
            paddingBottom: "0px",
            color: "orangered"
        }
        var txtstyle = {
            paddingLeft: "20px",
            paddingRight: "20px"
        }

        return (
            <div >
                <Navbar style={style} className="navbar" expand="lg">
                    <Navbar.Brand href="/">
                        <img alt="" src={logo} className="appLogo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto menucss">
                            <Nav.Link active={pathname == '/'} onMouseOver={() => this.menuhover()} href="/"><span style={txtstyle}>Home</span></Nav.Link>
                            <Nav.Link active={pathname.startsWith('/aboutus')} onMouseOver={() => this.menuhover()} href="/aboutus"><span style={txtstyle}>About US</span></Nav.Link>
                            <Nav.Link active={pathname.startsWith('/services')} href="/services"
                                onMouseOver={() => this.serviceshover()}
                            >Services <FontAwesomeIcon icon={faCaretDown} size="lg" />
                            </Nav.Link>
                            <Nav.Link active={pathname.startsWith('/industries')} href="/industries"
                                onMouseOver={() => this.industrieshover()}>
                                INDUSTRIES  <FontAwesomeIcon icon={faCaretDown} size="lg" />
                            </Nav.Link>
                            {/* <Nav.Link active={pathname.startsWith('/clients')} href="/clients"><span style={txtstyle}>Clients</span></Nav.Link> */}
                            <Nav.Link active={pathname.startsWith('/careers')} onMouseOver={() => this.menuhover()} href="/careers"><span style={txtstyle}>Careers</span></Nav.Link>
                            <Nav.Link active={pathname.startsWith('/contact')} onMouseOver={() => this.menuhover()} href="/contact"><span style={txtstyle}>Contact</span></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div id="servicesmenu" className="servicesmenu" align="left" onMouseLeave={() => this.serviceshoverout()}>
                    <br />
                    <FadeInSection>
                        <h4>Services</h4>
                        <div className="row">
                            <div className="col-3 submenu1">
                               
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/services/appservice">Application Services</a>
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/services/bpservice">Business Process Services</a>
                            </div>
                            <div className="col-3 submenu1">

                            </div>
                            <div className="col-3 submenu1">

                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/services/cloudservice">Cloud and Platform Services</a>
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/services/consulting">Consulting Services</a>
                            </div>
                            <div className="col-3 submenu1">

                            </div>
                            <div className="col-3 submenu1">

                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/services/security">Security Services</a>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
                <div id="industriesmenu" className="industriesmenu" align="left" onMouseLeave={() => this.industrieshoverout()}>
                    <br />
                    <h4>Industries</h4>
                    <FadeInSection>
                        <div className="row">
                            <div className="col-3 submenu1">
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/industries/healthcare">Healthcare</a>
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/industries/insurance">Insurance</a>
                            </div>
                            <div className="col-3 submenu1">
                            </div>
                            <div className="col-3 submenu1">
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/industries/travel">Travel, Transportation and Hospitality</a>
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/industries/banking">Banking and Capital Markets</a>
                            </div>
                            <div className="col-3 submenu1">
                            </div>
                            <div className="col-3 submenu1">
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/industries/retail">Consumer and Retail</a>
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/industries/utilities">Energy, Utilities, Oil and Gas</a>
                            </div>
                            <div className="col-3 submenu1">
                            </div>
                            <div className="col-3 submenu1">
                            </div>
                            <div className="col-3 submenu1">
                                <a className="submenu" href="/industries/telecomm">Technology, Media and Telecommunications</a>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}