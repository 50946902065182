import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import './Industries.css';

export default class Healthcare extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu2" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Healthcare</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div>
                    <FadeInSection>
                        <br />
                        <div className="container">
                            <h5 className="htxt">
                                Thought Circuits collaborates with the world's leading Life Sciences organizations to enable change
                                through strategy, insight, design, and technology. We solve our client's business challenges through 
                                innovative, patient-focused solutions.
                            </h5>
                            <br/>
                            <p>
                                Globally, healthcare enterprises including providers and payers are constantly striving to achieve 
                                three major objectives: Improve patient care experience, optimize health outcomes and reduce 
                                healthcare costs through healthcare consulting.
                            </p>
                            <p>
                                With managing health outcomes as central theme, healthcare organizations are striving to drive 
                                synergies in cost reduction, operational efficiency and delivery of care. However, for healthcare 
                                organizations to realize the triple aim, it is important to address inherent challenges of 
                                modernizing technology systems that have grown in an unstructured manner over a period of time. 
                                The challenges for healthcare consulting also include the need for integration of disparate systems, 
                                process and people along with optimization of interaction/touch points to enable seamless 
                                communication between different stakeholders.
                            </p>
                            <p>
                                Simultaneously, the healthcare industry has to navigate the ever-changing regulatory framework. 
                                From ICD-10 migration and meaningful use of health information technology to Affordable Care Act 
                                (ACA) and American Healthcare Act (AHCA), there is no respite for health management systems as 
                                they continue to invest significant time and money to keep pace with regulatory mandates. 
                                This is besides several other Medicare and Medicaid reforms such as MACRA that demand 
                                healthcare organizations to do "more for less".
                            </p>
                            <p>
                                This new normal requires significant investments in additional capabilities while also deflating the 
                                cost curve. Consequently, an ideal new age IT vendor therefore should have the acumen to understand 
                                how business processes and technology can be aligned to enable the patient centricity efforts of 
                                payers and providers.
                            </p>
                            <p>
                                As a technology leader in healthcare consulting, Thought Circuits is always at the cutting edge of
                                the quest to continuously improve on best practices in healthcare IT consulting services.
                                Our enterprise medical services have helped our customer to meet their Triple Aim goals while
                                maximizing their Return on Investment (RoI).
                            </p>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}