import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import { Checkmark } from 'react-checkmark';

export default class Utilities extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu7" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Energy, Utilities, Oil and Gas</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div>
                    <FadeInSection>
                        <br />
                        <div className="container">
                            <h5 className="htxt">
                                Does your energy company feel like it's operating on the edge ? For the first time, that might actually be a good thing.
                            </h5>
                            <br />
                            <p>
                                Energy and utility companies feel a level of disruption not seen in decades.
                                New, cash-rich competitors are disrupting the markets. Renewable prices continue to ratchet downward.
                                New energy storage technologies are rapidly becoming industrialized. Meanwhile, technology and oppurtuinity 
                                are moving to the edge.
                            </p>
                            <p>
                                Edge computing is placing capture and analysis of data wherever it's generated by customers on mobile
                                devices, IOT sensors in electric vehicles by machine to machine connections in smart buildings.
                            </p>
                            <p>Thought Circuits knows from experience how to help you:</p>
                            <div>
                                <ul>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Become a customer centric digital business</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Leverage IOT, machine learning and edge computing</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Identify, develop and implement new business models</span>
                                    </li>
                                    <li>
                                        <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Control costs and demonstrate value to customers and regulators</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}