import React, { Component } from 'react';
import './Contact.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FadeInSection from '../FadeInSection';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class Contact extends Component {


    render() {
        const classes = makeStyles((theme) => ({
            root: {
                '& .MuiTextField-root': {
                    margin: theme.spacing(1),
                    width: 600,
                },
            },
        }));

        const sendmessage = (event) => {
            event.preventDefault();
            var formData = new FormData();
            var name = event.target.elements.fullname.value;
            var email = event.target.elements.email.value;
            var message = event.target.elements.message.value;
            if (name === '' || email === '' || message === '') {
                NotificationManager.warning('Warning', 'Please enter all required fields!', 5000);
                return;
            }
            formData.append("fullname", name);
            formData.append("email", email);
            formData.append("message", message);
            var request = new XMLHttpRequest();
            request.open("POST", "/contact/sendmessage", true);
            request.send(formData);
            NotificationManager.success('Success', 'Your message has been delivered successfully!', 5000);
        }


        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgcss4" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Contact US</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div className="container contactsec">
                    <FadeInSection>
                        <div align="center">
                            <h1 className="hdrtxt"><strong>Get in Touch</strong></h1>
                            <br />
                            <h5>We are open to discussing your ideas and look forward to bringing them to life</h5>
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className="container contactsec">
                            <div className="row">
                                <div className="col-8 formsec">
                                    <form className={classes.root} noValidate autoComplete="off" onSubmit={sendmessage}>
                                        <TextField
                                            label="Full Name"
                                            id="fullname"
                                            defaultValue=""
                                            variant="outlined"
                                            size="small"
                                            required="true"
                                        />
                                        <br />
                                        <br />
                                        <TextField
                                            label="Email"
                                            id="email"
                                            defaultValue=""
                                            variant="outlined"
                                            size="small"
                                            required="true"
                                        />
                                        <br />
                                        <br />
                                        <TextField
                                            label="Message"
                                            id="message"
                                            defaultValue=""
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            size="small"
                                            required="true"
                                        />
                                        <br />
                                        <br />
                                        <button className="btn btn-primary btnclr">Send</button>
                                    </form>
                                </div>
                                <div className="col-4 addsec">
                                    <div className="container addsec1" align="center">
                                        <div>
                                            <h4><strong>CORPORATE OFFICE</strong></h4>
                                            <br />
                                            <h6>1300 W.Walnut Hill Lane,  Suite 106</h6>
                                            <h6>Irving, TX 75038</h6>
                                            <h6>contact@thoughtcircuits.com</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
                <NotificationContainer />
                <br />
                <br />
            </div>
        );
    }
}